<template>

  <div class="page-content">
    <div class="container-fluid">

      <Breadcrumb
          :pgTitle="$t('nav.workFields')"
          :pgIcon="'bx bx-flag'"
          :refs="refs"
          :pgCurrent="pgCurrent"
          :addNew="false">
      </Breadcrumb>

      <div class="row">

        <Loading
            v-if="pgLoading">
        </Loading>

        <div v-if="!pgLoading" class="col-8">

          <FormElements
              :label="$t('view.work_fields_info')"
              :labelTitle="$t('view.title')"
              :labelBody="$t('view.body')"

              :hasTitle=true
              :title_ar="row.title_ar"
              :title_en="row.title_en"

              :hasBody=true
              :body_ar="row.body_ar"
              :body_en="row.body_en"

              v-on:formChange="handleFormChange">
          </FormElements>

        </div>

        <div v-if="!pgLoading" class="col-4">

          <CardImage
              :labelImage="$t('view.image')"
              :image_base64="row.image_base64"
              :image_preview="row.image_preview"

              v-on:imageChange="handleImageChange">
          </CardImage>

          <CardInput
              :label="$t('app.sort')"
              :input="row.sort"
              :type="'number'"
              v-on:inputChange="handleSortChange">
          </CardInput>

          <CardStatus
              :status="row.status"
              v-on:statusChange="handleStatusChange">
          </CardStatus>

        </div>
      </div>


      <Buttons
          v-if="!pgLoading"
          :btnLoading="btnLoading"
          :btnCurrent="btnCurrent"

          v-on:submitClicked="handelSubmit"
          v-on:cancelClicked="handleCancel">
      </Buttons>

    </div>
  </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
  name: 'EditOrNew',
  components: {
    Breadcrumb: () => import('@/components/Breadcrumb.vue'),
    Loading: () => import('@/components/Loading.vue'),
    FormElements: () => import('@/components/FormElements.vue'),
    CardInput: () => import('@/components/CardInput.vue'),
    CardStatus: () => import('@/components/CardStatus.vue'),
    Buttons: () => import('@/components/Buttons.vue'),
    CardImage: () => import('@/components/CardImage.vue'),
  },
  data() {
    return {
      // auth
      auth: {
        role: '',
        access_token: '',
      },

      // row
      row: {
        title_ar: '',
        title_en: '',

        image_base64: '',
        image_preview: '',

        body_ar: '',
        body_en: '',

        sort: 0,
        status: 1,
      },

      msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
      btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
      methodType: (this.$route.params.id) ? 'PUT' : 'POST',
      pgLoading: (this.$route.params.id) ? true : false,
      pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
      btnLoading: false,

      refs: 'workFields',
    }
  },
  mounted() {
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem('access_token')) {
      this.auth.access_token = localStorage.getItem('access_token');
    }
    if (localStorage.getItem('role')) {
      this.auth.role = localStorage.getItem('role');
    }

    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {

    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + '/' + this.refs + '/' + this.$route.params.id,
        method: 'GET',
        data: {}
      }
      this.axios(options)
          .then(res => {
            this.pgLoading = false;

            this.row.title_ar = res.data.row.ar.title;
            this.row.title_en = res.data.row.en.title;

            this.row.body_ar = res.data.row.ar.body;
            this.row.body_en = res.data.row.en.body;

            this.row.image_preview = (res.data.row.image) ? res.data.row.image.url : '';
            this.row.image_base64 = (res.data.row.image) ? res.data.row.image.url : '';

            this.row.sort = res.data.row.sort;
            this.row.status = res.data.row.status;
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                name: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }

          })
          .finally(() => {
          });
    },


    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };

      const config = {headers: {'Content-Type': 'multipart/form-data'}};
      const options = {
        url: (this.$route.params.id) ? window.baseURL + '/' + this.refs + '/' + this.$route.params.id : window.baseURL + '/' + this.refs,
        method: this.methodType,
        data: {

          ar: {
            title: this.row.title_ar,
            body: this.row.body_ar
          },

          en: {
            title: this.row.title_en,
            body: this.row.body_en
          },
          image_base64: this.row.image_base64,
          sort: this.row.sort,
          status: this.row.status
        }
      }
      this.axios(options, config)
          .then(() => {
            this.btnLoading = false;
            iziToast.success({
              icon: 'bx bx-wink-smile',
              title: '',
              message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
            });

            this.$router.push({name: this.refs});
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                title: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }
          })
          .finally(() => {
          })

    },


    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('avatar');
      localStorage.removeItem('username');
      localStorage.removeItem('encrypt_id');
      localStorage.removeItem('role');
    },


    // Cancel
    handleCancel() {
      if (confirm('هل انت متأكد؟')) {
        this.$router.push({name: this.refs});
      }
    },

    handleImageChange(event) {
      this.row.image_base64 = event.image_base64;
    },
    // handel Changes from child components
    handleFormChange(event) {
      this.row.title_ar = event.title_ar;
      this.row.title_en = event.title_en;

      this.row.body_ar = event.body_ar;
      this.row.body_en = event.body_en;
    },

    handleSortChange(event) {
      this.row.sort = event.sort;
    },

    handleStatusChange(event) {
      this.row.status = event.status;
    },

  },
}
</script>
